module controllers {
    export module purchase {
        interface IDeliveryInstructionUpdateScope extends ng.IScope {
            diUpdateCtrl: controllers.purchase.PurchaseOrderUpdateCtrl
        }

        interface IDeliveryInstructionUpdateParams extends ng.ui.IStateParamsService {
            diId: number;
            poId: number;
        }

        export class deliveryInstructionUpdateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$stateParams', "$anchorScroll", "$state", "deliveryInstructionService", "routeCodeService",
                "transportModeService", "unLocationCodeService", "countryService", "warehouseService", "cartageContractorService", "notifyPartyService", "shippingPriorityService",
                "routeLeadTimesService", "bsLoadingOverlayService", "documentRepositoryService", "$uibModal", "sundryItemService", "currencyService",
                "deliveryInstructionSundryItemService", "calendarPeriodService", "$transitions", "$interval", "typeOfPackageService", 'permitService', 'transportTypeService','$timeout','purchaseOrderService',
                "customAttributeService", "customAttributeAnswerOptionService","shipperService"];

            breadCrumbDesc: string;
            selectedTab: number;

            showOnlyProhibited: boolean = false;
            deliveryInstruction: interfaces.purchase.IDeliveryInstruction;

            destinationCountryList: Array<interfaces.applicationcore.IDropdownModel>;
            placeOfUnloadingList: Array<interfaces.applicationcore.IDropdownModel>;
            deliverToWarehouseList: Array<interfaces.applicationcore.IDropdownModel>;

            routeLeadTimes: interfaces.master.IRouteLeadTimes;

            dateFormat: string = "YYYY/MM/DD";

            documentRepository: interfaces.documentManagement.IDocumentRepository;

            apiDIS: uiGrid.IGridApi;
            apiDISundry: uiGrid.IGridApi;

            costingSKUs: Array<interfaces.purchase.IDeliveryInstructionSKUAllocation> = [];

            notBeforeMin: moment.Moment = undefined;

            IsDisabled: boolean = false;

            showAll: boolean = false;

              //Attribute Grid
              apiCA: uiGrid.IGridApi;
              attributeList: interfaces.master.ICustomAttribute[] = [];
              attributeAnswerOptionList: interfaces.applicationcore.IDropdownModel[] = [];

            constructor(public $scope: IDeliveryInstructionUpdateScope, private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService, public $q: ng.IQService,
                public $stateParams: IDeliveryInstructionUpdateParams, public $anchorScroll: ng.IAnchorScrollService,
                public $state: ng.ui.IStateService, public deliveryInstructionService: interfaces.purchase.IDeliveryInstructionService,
                public routeCodeService: interfaces.master.IRouteCodeService, public transportModeService: interfaces.applicationcore.ITransportModeService,
                public unLocationCodeService: interfaces.applicationcore.IUNLocationCodeService, public countryService: interfaces.applicationcore.ICountryService,
                public warehouseService: interfaces.master.IWarehouseService, public cartageContractorService: interfaces.master.ICartageContractorService,
                public notifyPartyService: interfaces.master.INotifyPartyService, public shippingPriorityService: interfaces.master.IShippingPriorityService,
                public routeLeadTimesService: interfaces.master.IRouteLeadTimesService, public bsLoadingOverlayService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService, public $uibModal: angular.ui.bootstrap.IModalService,
                public sundryItemService: interfaces.master.ISundryItemService, public currencyService: interfaces.applicationcore.ICurrencyService,
                public deliveryInstructionSundryItemService: interfaces.purchase.IDeliveryInstructionSundryItemService,
                public calendarPeriodService: interfaces.master.ICalendarPeriodService, $transitions: ng.ui.core.ITransition,
                public $interval: ng.IIntervalService, public typeOfPackageService: interfaces.master.ITypeOfPackageService,
                private permitService: interfaces.master.IPermitService,
                private transportTypeService: interfaces.master.ITransportTypeService,
                public $timeout: ng.ITimeoutService,private purchaseOrderService: interfaces.purchase.IPurchaseOrderService,
                public customAttributeService: services.master.customAttributeService,
                public customAttributeAnswerOptionService: services.master.customAttributeAnswerOptionService,
                private shipperService: interfaces.master.IShipperService,) {

                bsLoadingOverlayService.wrap({
                    referenceId: 'di.update'
                },
                    () => {
                        return this.loadDI().then(() => {

                            bsLoadingOverlayService.wrap({
                                referenceId: 'di.update'
                            },
                                () => {
                                    return this.loadLeadTimes(this.deliveryInstruction.Route, this.deliveryInstruction.ShippingPriority);
                                });
                            this.breadCrumbDesc = this.deliveryInstruction.Code;
                        });
                    });

                //Reloads DI after add/edit of sundry item
                $transitions.onSuccess({
                    to: 'auth.PurchaseOrders.Update.Di',
                    from: 'auth.PurchaseOrders.Update.Di.**'
                }, () => {
                    this.loadDI();
                });
            }

            public loadDI() {
                var defer = this.$q.defer();

                this.deliveryInstructionService.getDetail().query({ Id: this.$stateParams.diId, ordId: this.$stateParams.poId }, (data: interfaces.purchase.IDeliveryInstruction) => {
                    this.deliveryInstruction = data;
                    if (!this.deliveryInstruction.NotAfterDate && this.deliveryInstruction.NotBeforeDate)
                    {
                        this.deliveryInstruction.NotAfterDate = this.deliveryInstruction.NotBeforeDate.clone();
                        this.deliveryInstruction.NotAfterDate.add(3, 'day');
                    }

                    this.gvwDIS.data = data.DeliveryInstructionSKUAllocations;
                    this.gvwDISundry.data = data.DeliveryInstructionSundryItems;
                    this.gvwPRList.data = data.DeliveryInstructionPRList;
                    this.gvwDeliveryInstructionAttribute.data = data.DeliveryInstructionAttributeList;

                    this.getDocuments(this.showAll).then(() => {
                        defer.resolve();
                    });
                }, (reponseError) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>reponseError.data);
                    defer.reject();
                });

                return defer.promise;
            }

            public loadRouteCodes() {
                var defer = this.$q.defer();

                if (this.deliveryInstruction) {
                    this.routeCodeService.getDropdownListByCountry(this.deliveryInstruction.OwnerEntityId, true, this.deliveryInstruction.LoadingCountryId).query((data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                } else {
                    defer.resolve([]);
                }
                

                return defer.promise;
            }

            public loadTransportModes() {
                var defer = this.$q.defer();

                this.transportModeService.getDropdownList().query({}, (data) => {
                    defer.resolve(data);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defer.resolve([]);
                });

                return defer.promise;
            }
            //Loading Transport Type
            loadTransportTypes(searchText: string) {

                if (this.deliveryInstruction) {
                    return this.transportTypeService.getDropdownList(this.deliveryInstruction.OwnerEntityId, searchText).query({
                    },
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            public loadPlaceOfLoading(searchText) {
                var defer = this.$q.defer();

                if ((<any>this.$scope.$parent).diUpdateCtrl.PurchaseOrder) {
                    this.unLocationCodeService.getDropdownList().query({ countryId: (<any>this.$scope.$parent).diUpdateCtrl.PurchaseOrder.CountryOfOrigin.Id, searchText: searchText }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                } else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            public loadCountries() {
                var defer = this.$q.defer();

                this.countryService.getDropdownList().query({}, (data) => {
                    defer.resolve(data);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defer.resolve([]);
                });

                return defer.promise;
            }

            public loadPlaceOfUnloading(searchText: string) {
                var defer = this.$q.defer();

                if (this.deliveryInstruction && this.deliveryInstruction.DestinationCountry) {
                    this.unLocationCodeService.getDropdownList().query({ countryId: this.deliveryInstruction.DestinationCountry.Id, searchText: searchText }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                }
                else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            public loadWarehouse() {
                var defer = this.$q.defer();

                if (this.deliveryInstruction && this.deliveryInstruction.PlaceOfUnloading) {
                    this.warehouseService.getDropdownList().query({ ownerEntityId: this.deliveryInstruction.OwnerEntityId, unLocationCodeId: this.deliveryInstruction.PlaceOfUnloading.Id }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                }
                else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            public loadCartageContractors() {
                var defer = this.$q.defer();

                if (this.deliveryInstruction && this.deliveryInstruction.PlaceOfUnloading) {
                    this.cartageContractorService.getDropdownList().query({ ownerEntityId: this.deliveryInstruction.OwnerEntityId }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                }
                else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            loadShippers(searchText: string) {
                
                return this.shipperService.getShipperDropdown(this.deliveryInstruction.OwnerEntityId , searchText).query({
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            shipperChanged(model: interfaces.applicationcore.IDropdownModel) {
                //Always try and get Route default from Shipper
                    this.loadShipperDefaults(model.Id);                
            }

            loadShipperDefaults(shipperId: number){
                return this.shipperService.getShipperDefaults(shipperId).get({

                }, (data: interfaces.master.IShipperDefaults) => {
                    if (data.NotifyParty) {
                        this.deliveryInstruction.NotifyParty = data.NotifyParty;
                    }                    
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }


            public loadNotifyParties() {
                var defer = this.$q.defer();

                if (this.deliveryInstruction) {
                    this.notifyPartyService.getDropdownList().query({ ownerEntityId: this.deliveryInstruction.OwnerEntityId }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                } else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            public loadShippingPriorities() {
                var defer = this.$q.defer();

                if (this.deliveryInstruction) {
                    this.shippingPriorityService.getDropdownList().query(
                        { 
                            entityId: this.deliveryInstruction.OwnerEntityId, 
                            transportModeId: this.deliveryInstruction.TransportMode?this.deliveryInstruction.TransportMode.Id:null,
                            IsInbound: true
                        }, 
                        (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
                } else {
                    defer.resolve([]);
                }

                return defer.promise;
            }

            public loadRouteDefaults(route: interfaces.applicationcore.IDropdownModel) {

                this.routeCodeService.getRouteData().query({ routeId: route ? route.Id : 0 },
                    (routeCodeData: interfaces.master.IRouteCode) => {
                        this.setRouteDefaults(routeCodeData);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                });
            }

            public setRouteDefaults(routeDefaultData: interfaces.master.IRouteCode) {
                if (routeDefaultData && routeDefaultData.Code) {

                    this.deliveryInstruction.PlaceOfLoading = routeDefaultData.LoadingLocation;
                    if (!this.deliveryInstruction.DestinationCountry || this.deliveryInstruction.DestinationCountry.Id != routeDefaultData.DestinationCountry.Id) {
                        this.deliveryInstruction.DestinationCountry = routeDefaultData.DestinationCountry;
                        this.placeOfUnloadingList = [];
                    }
                    this.deliveryInstruction.PlaceOfUnloading = routeDefaultData.UnloadingLocation;
                    this.deliveryInstruction.TransportMode = routeDefaultData.TransportMode;
                    if (routeDefaultData.ReceiptWarehouse !== null)
                        this.deliveryInstruction.DeliverToWarehouse = routeDefaultData.ReceiptWarehouse;
                    if (routeDefaultData.CartageContractor !== null)
                        this.deliveryInstruction.CartageContractor = routeDefaultData.CartageContractor;
                }
            }

            public loadLeadTimes(model: interfaces.applicationcore.IDropdownModel, shippingPriority: interfaces.applicationcore.IDropdownModel) {
                var defer = this.$q.defer();

                if (model && model.Id) {
                    this.routeLeadTimesService.getByRoute().query({ routeId: model.Id, shippingPriorityId: shippingPriority ? shippingPriority.Id : 0 }, (routeLeadTime) => {
                        this.routeLeadTimes = routeLeadTime;

                        if (this.deliveryInstruction.Week)
                            this.tradeWeekChanged(this.deliveryInstruction.Week);

                        defer.resolve();
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    });
                }
                else {
                    this.routeLeadTimes = <interfaces.master.IRouteLeadTimes>{
                        ETA: 0,
                        ShipByDate: 0,
                        CustomsClearedDate: 0,
                        DateToStore: 0,
                        RequiredDateOfArrival: 0,
                        RouteId: 0
                    };
                    
                    defer.resolve();
                }

                return defer.promise;
            }

            public calculateLeadTimes(forward: boolean, newValue: moment.Moment) {
                if (!newValue)
                    return;

                if (!this.routeLeadTimes)
                    return;

                if (!forward) {
                    //On Change Not Before Date
                    this.deliveryInstruction.NotAfterDate = newValue.clone().add(3, "day");
                    this.deliveryInstruction.RequiredDateOfArrival = newValue.clone().add((this.routeLeadTimes.RequiredDateOfArrival * -1), "day");
                    this.deliveryInstruction.ShippedByDate = this.deliveryInstruction.RequiredDateOfArrival.clone().add((this.routeLeadTimes.ETA * -1), "day");
                    this.deliveryInstruction.EarliestShippedByDate = this.deliveryInstruction.ShippedByDate.clone().add((this.routeLeadTimes.ShipByDate * -1), "day");
                    this.deliveryInstruction.SupplierDeliveryDueDate = this.deliveryInstruction.EarliestShippedByDate.clone().add((this.routeLeadTimes.SupplierDueDate * -1), "day");
                } else if (this.routeLeadTimes) {
                    //On Change Supplier Delivery Due Date
                    this.deliveryInstruction.EarliestShippedByDate = newValue.clone().add((this.routeLeadTimes.SupplierDueDate * 1), "day");
                    this.deliveryInstruction.ShippedByDate = this.deliveryInstruction.EarliestShippedByDate.clone().add((this.routeLeadTimes.ShipByDate * 1), "day");
                    this.deliveryInstruction.RequiredDateOfArrival = this.deliveryInstruction.ShippedByDate.clone().add((this.routeLeadTimes.ETA * 1), "day");
                    this.deliveryInstruction.NotBeforeDate = this.deliveryInstruction.RequiredDateOfArrival.clone().add((this.routeLeadTimes.RequiredDateOfArrival * 1), "day");
                    this.deliveryInstruction.NotAfterDate = this.deliveryInstruction.NotBeforeDate.clone().add(3, "day");
                }
            }

            public routeChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'di.update'
                },
                    () => {

                        var promises = [];
                        promises.push(this.loadRouteDefaults(model));
                        promises.push(this.loadLeadTimes(model, this.deliveryInstruction.ShippingPriority).then(
                            () => {
                                this.calculateLeadTimes(false, this.deliveryInstruction.NotBeforeDate);
                            }
                        ));

                        return this.$q.all(promises);
                    });
            }

            public shippingPriorityChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'di.update'
                },
                    () => {
                        return this.loadLeadTimes(this.deliveryInstruction.Route, model).then(
                            () => {
                                this.calculateLeadTimes(false, this.deliveryInstruction.NotBeforeDate);
                            }
                        );
                    });
            }

            public notBeforeDateChanged(newValue: moment.Moment) {
                this.calculateLeadTimes(false, newValue);
            }

            public supplierDeliveryDueDateChanged(newValue: moment.Moment) {
                this.calculateLeadTimes(true, newValue);
            }
            
            public destinationCountryChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.deliveryInstruction.DestinationCountry = model;
                this.deliveryInstruction.PlaceOfUnloading = undefined;
                this.placeOfUnloadingList = [];
            }
            
            public getDocuments(showAll:boolean) {
                return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.deliveryInstruction.Code, systemType: Enum.EnumSystemType.DeliveryInstruction, ownerEntityId: this.deliveryInstruction.OwnerEntityId, showAll:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    this.documentRepository = documentRepository;

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }

            printDocuments() {
                this.$state.go("auth.PurchaseOrders.Update.Di.Documents");
            }
            
            public gvwDIS: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                enablePinning: false,
                cellEditableCondition: () => { return this.$scope.diUpdateCtrl.PurchaseOrder ? this.$scope.diUpdateCtrl.PurchaseOrder.LockdownType < 2 : true },
                enableSorting: true,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerDISApi(gridApi) },
                columnDefs: [{
                    name: "COST",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.diUpdateCtrl.openDetailLineCosting(row.entity.Id)" ng-if="row.entity.Id">
                            <span class="fa fa-money"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                        name: "SEQ",
                        displayName: "#",
                        field: "POSequenceNumber",
                        width: 35,
                        cellClass: 'text-right',
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        enableFiltering: false
                    }, {
                        name: "SKUCODE",
                        displayName: "SKU",
                        field: "SKUCode",
                        width: 150,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    }, {
                        name: "SKUDESC",
                        displayName: "Description",
                        field: "SKUDescription",
                        width: 200,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "ORDQTY",
                    displayName: "Order Qty",
                        field: "OrderedQuantity",
                        type: "number",
                        cellClass: 'text-right',
                        cellFilter: 'number: 2',
                    width: 100,
                    enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-mouse class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "AVAILQTY",
                        displayName: "Available Qty",
                        field: "AvailableQuantity",
                        type: "number",
                        width: 100,
                        cellFilter: 'number: 2',
                        cellClass: 'text-right',
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "ALLOQTY",
                        displayName: "Allocated Qty",
                        field: "AllocatedQuantity",
                        type: "number",
                        width: 100,
                        cellFilter: 'number: 2',
                        cellClass: 'text-right',
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="orderedQty" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "INVOQTY",
                        displayName: "Invoice Qty",
                        field: "InvoiceQuantity",
                        type: "number",
                        cellFilter: 'number: 2',
                        cellClass: 'text-right',
                        width: 100,
                        enableCellEdit: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "RECVQTY",
                        displayName: "Received Qty",
                        field: "ReceivedQuantity",
                        type: "number",
                        cellFilter: 'number: 2',
                        cellClass: 'text-right',
                        width: 100,
                        enableCellEdit: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "OUTSQTY",
                        displayName: "Outstanding Qty",
                        field: "OutstandingQuantity",
                        type: "number",
                        cellFilter: 'number: 2',
                        cellClass: 'text-right',
                        width: 120,
                        enableCellEdit: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "SUPPRICE",
                        displayName: "Supplier Price",
                        field: "SupplierPrice",
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        width: 105,
                        enableCellEdit: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "CURCODE",
                        displayName: "Currency",
                        field: "CurrencyCode",
                        width: 100,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                    }, {
                        name: "UNITGLC",
                        displayName: "Unit GLC",
                        field: "UnitGLC",
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        width: 75,
                        enableCellEdit: false,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "CUSVALUE",
                        displayName: "Customs Value",
                        field: "CustomsValue",
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        width: 125,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="orderedQty" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.00000000000001" />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "NOPACKAGE",
                        displayName: "Number of Packages",
                        field: "NumberOfPackages",
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        width: 150,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="num-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="1" />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "TYPEOFPACKAGE",
                        displayName: "Type Of Package",
                        field: "TypeOfPackage",
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        width: 125,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.diUpdateCtrl.loadTypeOfPackages(searchText)" search-enabled="false"></gts-dropdown>
                                            </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                if (cellValue) {
                                    return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                } else {
                                    return false;
                                }
                            }
                        },
                        sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                            var nulls = this.apiDIS.core.sortHandleNulls(a, b);
                            if (nulls !== null) {
                                return nulls;
                            } else {
                                if (a === b) {
                                    return 0;
                                }

                                if (direction === "desc") {
                                    if (a.Display < b.Display) return -1;
                                    if (a.Display > b.Display) return 1;
                                } else if (direction === "asc") {
                                    if (a.Display > b.Display) return 1;
                                    if (a.Display < b.Display) return -1;
                                }

                                return 0;
                            }
                        }
                    }, {
                        name: "GROSSWEIGHT",
                        displayName: "Package Gross Weight",
                        field: "GrossWeight",
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        width: 155,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="orderedQty" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.00000000000001" />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "NETWEIGHT",
                        displayName: "Package Net Weight",
                        field: "NetWeight",
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        width: 150,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="orderedQty" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.00000000000001" />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }, {
                        name: "CUBE",
                        displayName: "Package Cube",
                        field: "Cube",
                        cellFilter: "number: 2",
                        cellClass: 'text-right',
                        width: 125,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | number:5}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="orderedQty" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.00000000000001" />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }]
            };

            public registerDISApi(gridApi: uiGrid.IGridApi) {
                this.apiDIS = gridApi;

                this.apiDIS.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiDIS.selection.selectRow(gridSelection.row.entity);
                });
            }

            loadTypeOfPackages(searchText: string) {
                return this.typeOfPackageService.getDropdownList(this.deliveryInstruction.OwnerEntityId).query(() => { }, (errorResponse) => { this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data); }).$promise;
            }

            public removeDetail() {
                this.generalService.displayConfirmationBox("Confirm?", "Are you sure you want to remove the SKU Line?").then((result: boolean) => {
                    if (result) {
                        if (this.apiDIS && this.apiDIS.selection.getSelectedRows().length > 0) {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'di.update'
                            },
                                () => {
                                    return this.deliveryInstructionService.removeSKUAllocation(this.apiDIS.selection.getSelectedRows()[0].Id).delete((data) => {
                                        this.generalService.displayMessageHandler(data);
                                        this.loadDI();
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    }).$promise;
                                });
                        }
                    }
                });
            }

            public loadCostingDetail() {
                return this.deliveryInstructionService.getCostingLineListForMultipleCostings().query({ diId: this.deliveryInstruction.Id }).$promise;
            }

            public recostLines() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'di.update'
                },
                    () => {
                        return this.deliveryInstructionService.calculateDeliveryInstructionCost().save({}, this.deliveryInstruction, (data) => {
                            this.generalService.displayMessageHandler(data);
                            this.loadDI();
                        }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                    });

               
            }

            public openDetailLineCosting(disId: number) {
                this.$uibModal.open({
                    animation: true,
                    size: "lg",
                    template: `
                            <div bs-loading-overlay bs-loading-overlay-reference-id="costing.detail.load">
                               <div class="modal-header">
                                  <h3 class="modal-title" id="modal-title">Costing Detail</h3>
                               </div>
                               <div class="modal-body" id="modal-body">
                                  <gts-costing entity-id="disCostingCtrl.deliveryInstruction.OwnerEntityId" cost-model-type="244" ng-model="disCostingCtrl.deliveryInstruction.CostingModel" dis-id="disCostingCtrl.disId" recost="disCostingCtrl.recostLines()"></gts-costing>
                               </div>
                               <div class="modal-footer">
                                  <button class="btn btn-primary" type="button" ng-click="disCostingCtrl.ok()">Ok</button>
                               </div>
                            </div>
                        `,
                    controller: class diDetailCostingCtrl {
                        disId: number;
                        deliveryInstruction: interfaces.purchase.IDeliveryInstruction

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, private items, private bsLoadingOverlayService,
                            private deliveryInstructionService: interfaces.purchase.IDeliveryInstructionService, private generalService) {
                            this.disId = items.disId;
                            this.deliveryInstruction = items.deliveryInstruction;
                        }

                        ok() {
                            this.$uibModalInstance.close();
                        }

                        recostLines() {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'costing.detail.load'
                            },
                                () => {
                                    return this.deliveryInstructionService.calculateDeliveryInstructionCost().save({ deliveryInstructionSkuAllocationId: this.disId}, this.deliveryInstruction, (data) => {
                                        this.generalService.displayMessageHandler(data);
                                        this.$uibModalInstance.close(true);
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                        this.$uibModalInstance.close(false);
                                    }).$promise;
                                });
                        }
                    },
                    controllerAs: 'disCostingCtrl',
                    resolve: {
                        items: () => {
                            return {
                                disId: disId,
                                deliveryInstruction: this.deliveryInstruction
                            };
                        }
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadDI();
                    }
                });
            }
            
            public gvwDISundry: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerDISundryApi(gridApi); },
                cellEditableCondition: () => { return this.$scope.diUpdateCtrl.PurchaseOrder ? this.$scope.diUpdateCtrl.PurchaseOrder.LockdownType < 2 : true },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    field: "Id",
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-if="MODEL_COL_FIELD || MODEL_COL_FIELD <= 0" class="btn btn-default btn-sm" ui-sref="auth.PurchaseOrders.Update.Di.SundryItem({ pdsId: MODEL_COL_FIELD })">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-disabled="grid.appScope.diUpdateCtrl.PurchaseOrder.LockdownType > 1" ng-click="grid.appScope.diUpdateCtrl.addInlineSundryItem()">
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>`
                },{
                    name: "SEQ",
                    displayName: "#",
                    field: 'SequenceNumber',
                    enableFiltering: false,
                        cellClass: 'text-right',
                        cellFilter: 'number: 0',
                    width: 35
                }, {
                    name: "CODE",
                    displayName: "*Sundry Item Code",
                    field: 'SundryItem',
                    enableCellEdit: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Code}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.diUpdateCtrl.loadSundryItem(searchText)" required="true" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                            </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                if (cellValue) {
                                    return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                } else {
                                    return false;
                                }
                            }
                        },
                        sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                            var nulls = this.apiDIS.core.sortHandleNulls(a, b);
                            if (nulls !== null) {
                                return nulls;
                            } else {
                                if (a === b) {
                                    return 0;
                                }

                                if (direction === "desc") {
                                    if (a.Display < b.Display) return -1;
                                    if (a.Display > b.Display) return 1;
                                } else if (direction === "asc") {
                                    if (a.Display > b.Display) return 1;
                                    if (a.Display < b.Display) return -1;
                                }

                                return 0;
                            }
                        }
                }, {
                    name: "DESC",
                    displayName: "*Sundry Item Description",
                    field: 'SundryItem',
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    width: 275,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Description}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="desc-{{MODEL_COL_FIELD.$$hashKey}}" autocomplete="off" ng-model="MODEL_COL_FIELD.Description" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor ng-change="grid.appScope.diUpdateCtrl.apiDISundry.rowEdit.setRowsDirty([row.entity]);" />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "QTY",
                    displayName: "*Quantity",
                    field: 'Quantity',
                    width: 100,
                    enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellClass: 'text-right',
                        cellFilter: 'number: 6',
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="qty-{{MODEL_COL_FIELD.$$hashKey}}" autocomplete="off" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                }, {
                    name: "SELLPRICE",
                    displayName: "*Price",
                    field: 'SellingPrice',
                    width: 100,
                    enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellClass: 'text-right',
                        cellFilter: 'number: 5',
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="qty-{{MODEL_COL_FIELD.$$hashKey}}" autocomplete="off" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                }, {
                    name: "SELLVALUE",
                    displayName: "Value",
                    field: 'SellingValue',
                        cellClass: 'text-right',
                        cellFilter: 'number: 2',
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                }, {
                    name: "VATVALUE",
                    displayName: "VAT Value",
                    field: 'VATValue',
                        cellClass: 'text-right',
                        cellFilter: 'number: 2',
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" ignore-wheel class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                }, {
                    name: "CURCODE",
                    displayName: "Currency",
                    field: 'Currency',
                    enableCellEdit: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Code}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="currency-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.diUpdateCtrl.loadCurrencies(searchText)" required="true"></gts-dropdown>
                                            </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                if (cellValue) {
                                    return (cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                } else {
                                    return false;
                                }
                            }
                        },
                        sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                            var nulls = this.apiDIS.core.sortHandleNulls(a, b);
                            if (nulls !== null) {
                                return nulls;
                            } else {
                                if (a === b) {
                                    return 0;
                                }

                                if (direction === "desc") {
                                    if (a.Display < b.Display) return -1;
                                    if (a.Display > b.Display) return 1;
                                } else if (direction === "asc") {
                                    if (a.Display > b.Display) return 1;
                                    if (a.Display < b.Display) return -1;
                                }

                                return 0;
                            }
                        }
                }]
            };

            public registerDISundryApi(gridApi: uiGrid.IGridApi) {
                this.apiDISundry = gridApi;

                this.apiDISundry.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiDISundry.selection.selectRow(gridSelection.row.entity);
                });
            }

            public CopyCostingFromClick(){
                if (this.apiDIS.selection.getSelectedRows().length > 0)
                {
                    var selectedItem = <interfaces.purchase.IDeliveryInstructionSKUAllocation>this.apiDIS.selection.getSelectedRows()[0];
                    
                    if (selectedItem.AllocatedQuantity!==0 && selectedItem.Id>0)
                    {
                        this.displaySKUAllocations((<any>this.$scope.$parent).diUpdateCtrl.PurchaseOrder.LinkedPurchaseOrderId).then((result: interfaces.purchase.IDeliveryInstructionSKUAllocation) => {
                            if (result) {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'di.update'
                                },
                                    () => {         
                                        
                                        return this.purchaseOrderService.copyCostingFromParentToChildOrderByLine().save({
                                            sourceSKUAllocationId: result.Id,
                                            destinationSKUAllocationId: selectedItem.Id,
                                        },
                                            (result: interfaces.applicationcore.IMessageHandler) => {
                                                this.generalService.displayMessageHandler(result);
                                                this.apiDIS.rowEdit.setRowsClean(this.apiDIS.selection.getSelectedRows());
                        
                                            }, (failureMessage) => {
                                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                        
                                            });

                                    });                                    
                            }
                        });      
                        
                    }
                    else
                    {
                        this.generalService.displayMessage('Allocated Quantity must be > 0',Enum.EnumMessageType.Warning);
                    }
                }
                else
                {
                    this.generalService.displayMessage('Please select a SKU Allocation line',Enum.EnumMessageType.Warning);
                }
            }

        

           public displaySKUAllocations(purchaseOrderId: number)
            {

                     return this.$uibModal.open({
                        animation: true,
                        template: `
                            <div class="modal-content" >
                                <form name="payInvoice">
                                    <div class="gts-padding">
                                        <fieldset id="ordSKUs">
                                        <legend>
                                        SKU Allocation
                                        </legend>                                            
                                        <div class="vertical-align">
                                            <div class="col-md-12">
                                                <div id="gridTest" ui-grid="gvwSKU" ui-grid-resize-columns ui-grid-pagination ui-grid-cellNav class="gridSmallScreen"
                                                        ng-if="gvwSKU">
                                                        <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="IsLoading">
                                                            <div class="loadingGrid" ng-show="IsLoading">
                                                                <i class="fa fa-spinner fa-spin"></i>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        </fieldset>
                                    </div>
                                </form>
                            </div>
                        `
                        ,
                        controller: ($scope,  $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, deliveryInstructionService: interfaces.purchase.IDeliveryInstructionService) => {
                                $scope.purchaseOrderId = purchaseOrderId;

                            $scope.cancel = () => {
                                $uibModalInstance.dismiss();
                            }
    
                            $scope.ok = (entity: interfaces.purchase.IDeliveryInstructionSKUAllocation) => {
                                $uibModalInstance.close(entity);
                            };   

                            $scope.gvwSKU = {
                            data: [],
                            enableFiltering: true,
                            useExternalFiltering: false,
                            enableCellEdit: false,
                            useExternalSorting: false,
                            multiSelect: false,
                            enableColumnResizing: true,
                            enableFullRowSelection: false,
                            useExternalPagination: false,
                            paginationPageSizes: [15, 30, 45, 60],
                            paginationPageSize: 15,
                            enableRowHeaderSelection: false,
                            enableHorizontalScrollbar: 2,
                            enableVerticalScrollbar: 1,
                            onRegisterApi: (gridApi) => {
                                $scope.IsLoading = true;

                                deliveryInstructionService.getListByPOId($scope.purchaseOrderId).query((result: Array<interfaces.purchase.IDeliveryInstructionSKUAllocation>) => {
                                    $scope.gvwSKU.data = result;
                                    $scope.IsLoading = false;
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    $scope.IsLoading = false;
                                });
                            },
                            columnDefs: [
                                {
                                    name: "EDIT",
                                    displayName: "",
                                    field: "Id",
                                    cellTemplate: `
                                    <div class="GridButton"> 
                                        <button type="button" ng-click="grid.appScope.ok(row.entity)" class="btn  btn-primary btn-sm">
                                            Select
                                        </button>
                                    </div>`,
                                    enableSorting: false,
                                    enableColumnMenu: false,
                                    enableCellEdit: false,
                                    width: 54,
                                    enableCellEditOnFocus: false
                                },                              
                                {
                                name: "DICode",
                                displayName: "DI Code",
                                field: "DICode",
                                type: "string",
                                width: 120,
                            }, {
                                name: "SKUCode",
                                displayName: "SKU Code",
                                field: "SKUCode",
                                type: "string",
                                width: 140,
                            }, {
                                name: "SKUDescription",
                                displayName: "SKU Description",
                                field: "SKUDescription",
                                type: "string",
                                width: 200,
                            }]
                        };                    
                        },
                        controllerAs: "SKUcTRL",
                        size: "md",
                        resolve: {
                            
                        }
                    }).result;
                }        

            public removeSundryItem() {
                if (this.apiDISundry.selection.getSelectedRows().length > 0) {

                    var selectedItem = <interfaces.purchase.IDeliveryInstructionSundryItemDisplay>this.apiDISundry.selection.getSelectedRows()[0];

                    this.generalService.displayConfirmationBox("Confirm", "Remove selected sundry item? " + selectedItem.SundryItem.Display).then((result: boolean) => {
                        if (result) {
                            if (!selectedItem.Id) {
                                var index = (<Array<interfaces.purchase.IDeliveryInstructionSundryItemDisplay>>this.gvwDISundry.data).indexOf(selectedItem);
                                (<Array<interfaces.purchase.IDeliveryInstructionSundryItemDisplay>>this.gvwDISundry.data).splice(index, 1);
                            } else if (selectedItem.Id > 0) {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'di.update'
                                },
                                    () => {

                                        return this.deliveryInstructionSundryItemService.delete().delete({ pdsId: selectedItem.Id }, (messageHandler: interfaces.applicationcore.IMessageHandler) => {
                                            this.generalService.displayMessageHandler(messageHandler);
                                            this.loadDI();
                                        }, (errorResponse) => {
                                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                        }).$promise;
                                    });
                            }
                        }
                    });
                }
                
            }

            public generateSundryItems_click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'di.update'
                },
                    () => {

                        return this.SaveDI().then((msgHandler: interfaces.applicationcore.IMessageHandler) => {

                            if (!msgHandler.HasErrorMessage) {
                                this.generateSundryItems();
                            } else {
                                this.generalService.displayMessageHandler(msgHandler);
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                    });
            }

            GoCalculateExchangeRate() {
                var defered = this.$q.defer();

                this.deliveryInstructionService.recalculateExchangeRate().save(this.deliveryInstruction,
                    (result: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(result);
                        defered.resolve(result);

                    }, (failureMessage) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                        defered.reject(failureMessage);
                    });

                return defered.promise;
            }

            RecalculateExchangeRate() {
                
                this.generalService.displayConfirmationBox("Confirm", "Are you sure you want to recalculate the exchange rate?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'di.update'
                        },
                            () => {
                                return this.GoCalculateExchangeRate().then((data) => {
                                    this.$anchorScroll("topAnchor");
                                    this.loadDI();
                                }, (data) => {
                                    this.$anchorScroll("topAnchor");
                                });
                            })
                    }
                });
            }

            generateSundryItems() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'di.update'
                },
                    () => {

                        return this.deliveryInstructionSundryItemService.generateSundryItems().get({ pdiId: this.deliveryInstruction.Id }, (msgHandler: interfaces.applicationcore.IMessageHandler) => {
                            this.generalService.displayMessageHandler(msgHandler);

                            this.loadDI();
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                    });
            }

            addInlineSundryItem() {
                (<Array<any>>(this.gvwDISundry.data)).splice(0, 0, <interfaces.purchase.IDeliveryInstructionSundryItemDisplay>{
                    Quantity: 0,
                    SellingValue: 0,
                    SellingPrice: 0,
                    VATValue: 0
                });
            }

            loadSundryItem(searchText: string) {
                if (this.deliveryInstruction) {

                    return this.sundryItemService.getDropdownListForCostModel(this.deliveryInstruction.OwnerEntityId, this.deliveryInstruction.CostingModel.CostModel.Id, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadCurrencies() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(currencyList);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });
                
                return defered.promise;
            }

            loadCalendarPeriods() {
                if (this.deliveryInstruction) {
                    return this.calendarPeriodService.getDropdownList().query({ ownerEntityId: this.deliveryInstruction.OwnerEntityId, calendarTypeId: Enum.EnumCalendarType.TradingCalendar, startDate: this.deliveryInstruction.PurchaseOrderDate.format("YYYYMMDDTHH:mm") }, (resultList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                }

                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                defered.resolve([]);

                return defered.promise;
            }

            tradeWeekChanged(model: interfaces.applicationcore.IDropdownModel) {
                if ((model && !this.deliveryInstruction.Week)) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'di.update'
                    },
                        () => {
                            return this.calendarPeriodService.get(model.Id).get((result: interfaces.master.ICalendarPeriod) => {
                                this.deliveryInstruction.NotBeforeDate = result.StartDate;
                                this.notBeforeMin = result.StartDate;
                                this.notBeforeDateChanged(result.StartDate);
                                this.deliveryInstruction.NotAfterDate = result.EndDate;
                            }, () => {

                                }).$promise;
                        });
                } else if (this.deliveryInstruction.Week) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'di.update'
                    },
                        () => {
                            return this.calendarPeriodService.get(model.Id).get((result: interfaces.master.ICalendarPeriod) => {
                                this.deliveryInstruction.NotBeforeDate = result.StartDate;
                                this.notBeforeMin = result.StartDate;
                                this.notBeforeDateChanged(result.StartDate);
                                this.deliveryInstruction.NotAfterDate = result.EndDate;
                            }, () => {

                            }).$promise;
                        });
                } else {
                    this.notBeforeMin = undefined
                }
            }

            downloadPRToExcel() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'di.update'
                },
                    () => {
                        this.deliveryInstructionService.GetDeliveryInstructionPRListExcel(this.deliveryInstruction.Id, this.showOnlyProhibited).then(() => {
                        }, (data) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                        });
                    });
            }

            showOnlyPRProhibited() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'di.update'
                }, () => {
                        return this.deliveryInstructionService.ViewDetailPR().query({
                            diId: this.deliveryInstruction.Id,
                            showOnlyProhibited: this.showOnlyProhibited,
                    }, (result: Array<interfaces.purchase.IDeliveryInstructionPRDisplay>) => {

                        this.deliveryInstruction.DeliveryInstructionPRList = result;
                        this.gvwPRList.data = result;

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            }


            //////////////////////////////////////////////
            //P&R List.
            paginationPROptions = {
                pageNumber: 1,
                pageSize: 25
            };

            apiPRList: uiGrid.IGridApi;
            gvwPRList: uiGrid.IGridOptions | any = {
                data: [],
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: false,
                //Pagination
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                //Menu + Download
                enableGridMenu: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: false,
                exporterExcelFilename: "SalesOrderPRList.xlsx",
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['EDIT', 'Notes'],
                //Filters
                enableFiltering: true,
                useExternalFiltering: false,
                onRegisterApi: (gridApi) => { this.registerPRGridApi(gridApi) },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "",
                        width: 20,
                        enableFiltering: false,
                        enableSorting: false,
                        enableColumnMenu: false,
                    }, {
                        name: "ProductCode",
                        displayName: "Product Code",
                        field: "ProductCode",
                        width: 100,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "ProductDescription",
                        displayName: "Description",
                        field: "ProductDescription",
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        width: 210,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "CountryCode",
                        displayName: "Country",
                        field: "CountryCode",
                        width: 73,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "Flow",
                        displayName: "Flow",
                        field: "Flow",
                        width: 60,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "Restriction",
                        displayName: "Restriction",
                        field: "Restriction",
                        width: 220,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}"><a href="/ApplicationCore/GTS/index.html#!/ProhibitedAndRestricted/RestrictionDetail/{{MODEL_COL_FIELD.Id}}" target="_blank">{{MODEL_COL_FIELD.Display}}</a></div>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return (searchTerm.length === 0 || cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                            }
                        },
                    }, {
                        name: "IsProhibited",
                        displayName: "Prohibited",
                        field: "IsProhibited",
                        width: 84,
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD">`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 200 }"/></div>
                            </div>`
                    }, {
                        name: "IsException",
                        displayName: "Is Exception",
                        field: "IsException",
                        width: 91,
                        cellTemplate: `<input type="checkbox" ng-disabled="false" ng-model="MODEL_COL_FIELD" ng-click="grid.appScope.diUpdateCtrl.permitExceptionChanged(row)">`,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        editableCellTemplate:
                            `   <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="checkbox" ng-disabled="false" ng-model="MODEL_COL_FIELD" ng-click="grid.appScope.diUpdateCtrl.permitExceptionChanged(row.entity)">
                            </p>
                        </form>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 200 }"/></div>
                            </div>`
                    }, {
                        name: "Permit",
                        displayName: "Permit",
                        field: "Permit",
                        width: 160,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                        <form name="inputForm">                                               
                            <p class="input-group-sm" ng-show="row.entity.RestrictionQuotaTypeId === 755">
                                <input type="text" name="PermitNumber-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD.Display" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor ng-change="grid.appScope.diUpdateCtrl.permitCodeChange(row)" />
                            </p>                           
                        </form>
                        <gts-dropdown ng-show="row.entity.RestrictionQuotaTypeId != 755" ui-select-wrap name="Permit-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" ng-change="grid.appScope.diUpdateCtrl.permitCodeDropdownChange(row)" load-data="grid.appScope.diUpdateCtrl.loadPermits(row, searchText)" pagination-search="true" refresh-delay="1000"></gts-dropdown>`,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.deliveryInstruction ? this.deliveryInstruction.LockdownType < 3 : true },
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                if (cellValue) {
                                    if (cellValue.Description) {
                                        return (searchTerm.length === 0 || cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                                    } else {
                                        return (searchTerm.length === 0 || cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                                    }
                                }
                            }
                        },
                    }, {
                        name: "PermitDisplay",
                        displayName: "",
                        field: "Permit",
                        width: 50,
                        enableFiltering: false,
                        enableSorting: false,
                        enableColumnMenu: false,
                        cellTemplate: `<div class="ui-grid-cell-contents"><a ng-show="{{MODEL_COL_FIELD.Id}}" href="/ApplicationCore/GTS/index.html#!/MasterData/Permit/UpdatePermit/{{MODEL_COL_FIELD.Id}}" target="_blank">View</a></div>`,
                    }, {
                        name: "IsQuota",
                        displayName: "Quota",
                        field: "IsQuota",
                        width: 58,
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD">`,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 200 }"/></div>
                        </div>`
                    }, {
                        name: "IsQuotaAllocated",
                        displayName: "Quota Allocated",
                        field: "IsQuotaAllocated",
                        width: 150,
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD">`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 200 }"/></div>
                            </div>`
                    }, {
                        name: "QuotaRequiredValue",
                        displayName: "Quota Value",
                        field: "QuotaRequiredValue",
                        width: 150,
                        cellFilter: "number: 2",
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "QuotaAllocatedValue",
                        displayName: "Allocated Value",
                        field: "QuotaAllocatedValue",
                        width: 150,
                        cellFilter: "number: 2",
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.deliveryInstruction ? this.deliveryInstruction.LockdownType < 3 : true },
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                        editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="number" ng-disabled="!(row.entity.RestrictionQuotaTypeId === 798 || row.entity.RestrictionQuotaTypeId === 740 || !row.entity.QuotaRequiredValue)" name="QuotaAllocatedValue-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" />
                                </p>
                            </form>`,
                    }, {
                        name: "QuotaCurrency",
                        displayName: "Currency",
                        field: "QuotaCurrency",
                        width: 75,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "QuotaRequiredAmount",
                        displayName: "Quota Amount",
                        field: "QuotaRequiredAmount",
                        width: 150,
                        cellFilter: "number: 2",
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    }, {
                        name: "QuotaAllocatedAmount",
                        displayName: "Allocated Amount",
                        field: "QuotaAllocatedAmount",
                        width: 150,
                        cellFilter: "number: 2",
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: () => { return this.deliveryInstruction ? this.deliveryInstruction.LockdownType < 3 : true },
                        filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                        editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="number" ng-disabled="!(row.entity.RestrictionQuotaTypeId === 798 || row.entity.RestrictionQuotaTypeId === 739 || !row.entity.QuotaRequiredAmount)" name="QuotaAllocatedAmount-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" />
                            </p>
                        </form>`,
                    }, {
                        name: "QuotaUnitOfMeasure",
                        displayName: "UoM",
                        field: "QuotaUnitOfMeasure",
                        width: 58,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    }, {
                        name: "TariffCode",
                        displayName: "Tariff Code",
                        field: "TariffCode",
                        width: 110,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }, {
                        name: "Comments",
                        displayName: "Comments",
                        field: "Comments",
                        width: 125,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    }]
            };

            registerPRGridApi(gridApi: uiGrid.IGridApi) {
                this.apiPRList = gridApi;

                this.apiPRList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.PRListPageChange(newPage, pageSize) });

                this.apiPRList.cellNav.on.navigate(this.$scope, (newCol) => {
                    this.$timeout(() => {
                        this.apiPRList.selection.selectRow(newCol.row.entity);
                    });
                });
            }

            PRListPageChange(newPage, pageSize) {
                this.paginationPROptions.pageNumber = newPage;
                this.paginationPROptions.pageSize = pageSize;
            }

            loadPermits(row: uiGrid.IGridRow, searchText: string) {
                return this.deliveryInstructionService.GetPermitsForDropdown().query({
                    deliveryInstructionRestrictionId: row.entity.Id,
                    searchText: searchText
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            permitExceptionChanged(row: uiGrid.IGridRow) {
                this.apiPRList.rowEdit.setRowsDirty([row.entity]);
            }

            permitCodeChange(row: uiGrid.IGridRow) {
                this.apiPRList.rowEdit.setRowsDirty([row.entity]);
                row.entity.Permit.Id = null;
                row.entity.Permit.Description = row.entity.Permit.Display;
            }

            checkPR() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'di.update'
                }, () => {
                    return this.SaveDI().then(() => {
                        this.deliveryInstructionService.CheckForPR(this.deliveryInstruction.Id).save((result) => {
                            this.generalService.displayMessageHandler(result);
                            this.$anchorScroll("topAnchor");

                            if (!result.HasErrorMessage) {
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'di.update'
                                    }, () => {
                                        return this.loadDI();
                                    });
                                });
                            }
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            this.$anchorScroll("topAnchor");
                            }).$promise;
                    }, () => {
                        return;
                    });
                });
            }

            addPermit() {
                if (this.apiPRList && this.apiPRList.selection.getSelectedRows().length > 0) {

                    var selected: interfaces.purchase.IDeliveryInstructionPRDisplay = this.apiPRList.selection.getSelectedRows()[0];

                    if (selected.Restriction === null) {
                        this.generalService.displayMessage("Only P&R Lines linked to restrictions can be split.", Enum.EnumMessageType.Warning);
                    } else {


                        var newPR = {
                            Id: selected.Id,
                            ProductCode: selected.ProductCode,
                            ProductDescription: selected.ProductDescription,
                            CountryCode: selected.CountryCode,
                            Flow: selected.Flow,
                            TariffCode: selected.TariffCode,
                            Restriction: selected.Restriction,
                            RestrictionQuotaTypeId: selected.RestrictionQuotaTypeId,
                            RestrictionQuotaTypeDesc: selected.RestrictionQuotaTypeDesc,
                            IsProhibited: true,
                            IsException: false,
                            IsQuotaAllocated: false,

                            QuotaRequiredValue: selected.QuotaRequiredValue,
                            QuotaCurrency: selected.QuotaCurrency,
                            QuotaRequiredAmount: selected.QuotaRequiredAmount,
                            QuotaUnitOfMeasure: selected.QuotaUnitOfMeasure,

                            Comments: selected.Comments,

                            RestrictionPermitId: 0,
                            Permit: null,
                            IsQuota: selected.IsQuota,
                            QuotaAllocatedValue: 0,
                            QuotaAllocatedAmount: 0,
                            RestrictionPermitActive: true
                        };

                        (<Array<any>>(this.gvwPRList.data)).splice(0, 0, newPR);
                    }

                } else {
                    this.generalService.displayMessage("Please select a P&R Line", Enum.EnumMessageType.Warning);
                }
            }

            removePermit() {
                if (this.apiPRList && this.apiPRList.selection.getSelectedRows().length > 0) {

                    var selected: interfaces.purchase.IDeliveryInstructionPRDisplay = this.apiPRList.selection.getSelectedRows()[0];
                    selected.RestrictionPermitActive = false;
                    this.apiPRList.rowEdit.setRowsDirty([selected]);

                    this.Save();

                } else {
                    this.generalService.displayMessage("Please select a P&R Line", Enum.EnumMessageType.Warning);
                }
            }

            runQuotaAllocation() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'di.update'
                }, () => {
                    return this.SaveDI().then(() => {
                        this.deliveryInstructionService.RunQuotaAllocation(this.deliveryInstruction.Id).save({
                            diId: this.deliveryInstruction.Id,
                        }, (result) => {
                            this.generalService.displayMessageHandler(result);
                            this.$anchorScroll("topAnchor");

                            //if (!result.HasErrorMessage) { //this check has been removed as we always need to refresh the P&R grid after quota allocation
                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'di.update'
                                    }, () => {
                                        return this.loadDI();
                                    });
                                });
                           //}
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            this.$anchorScroll("topAnchor");
                            });
                    }, () => {
                        return;
                    });
                });
            }

            //Delivery Instruction Custom Attribute START

            registerPurchaseOrderAttributeApi(gridApi: uiGrid.IGridApi) {
                this.apiCA = gridApi;
            }

            removeInlineCA() {
                var selectedRow = <interfaces.master.ICustomAttributeValue>this.apiCA.selection.getSelectedRows()[0];
                if (selectedRow && selectedRow.Id > 0) {
                    return this.bsLoadingOverlayService.wrap({
                        referenceId: 'di.update'
                    }, () => {
                        return this.customAttributeService.deleteCustomAttributeValue(selectedRow.Id).save({ Id: selectedRow.Id }, (result) => {
                            this.generalService.displayMessageHandler(result);
                            this.$timeout(() => {
                                return this.bsLoadingOverlayService.wrap({
                                    referenceId: 'di.update'
                                }, () => {
                                        this.loadDI();
                                });
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                    });
                } else if (selectedRow) {
                    _.remove(this.gvwDeliveryInstructionAttribute.data, (o) => {
                        return o === selectedRow;
                    });
                }
            }

            checkBoxAttributeChanged(entity) {
                if (this.apiCA) {
                    this.apiCA.rowEdit.setRowsDirty([entity]);
                }
            }

            attributeValueChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.master.ICustomAttributeValue) {
                if (model) {
                    entity.AttributeValue = model.Description;

                    if (this.apiCA) {
                        this.apiCA.rowEdit.setRowsDirty([entity]);
                    }
                }
            }

            attributeValueOtherChanged(entity: interfaces.master.ICustomAttributeValue) {
                if (entity) {
                    if (this.apiCA) {
                        this.apiCA.rowEdit.setRowsDirty([entity]);
                    }
                }
            }

            loadAttributeAnswerOptionList(id) {
                return this.customAttributeAnswerOptionService.GetDropDownListByAttribute().query({
                    attributeId: id
                }, (resultList) => {
                    this.attributeAnswerOptionList = resultList;
                }).$promise;
            }

            insertCA() {
                this.deliveryInstruction.DeliveryInstructionAttributeList.push(<interfaces.master.ICustomAttributeValue>{
                    Id: 0,
                    Attribute: <interfaces.master.ICustomAttribute>{ Id: 0, AttributeType: 0, IsNotEditable: false },
                    CategoryType: 825,
                    CategoryKey: this.deliveryInstruction.Id,
                    IsActive: true
                });
            }

            loadAttributeList() {
                return this.customAttributeService.GetCustomAttributeByCategory(825,this.deliveryInstruction.OwnerEntityId).query(
                {
                }, (resultList:interfaces.master.ICustomAttribute[]) => {
                    this.attributeList = resultList;
                }).$promise;
            }

            gvwDeliveryInstructionAttribute: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="col.colIndex()" class="ui-grid-cell" ng-disabled="row.entity.Attribute.IsNotEditable" ui-grid-cell></div>',
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => {
                    this.apiCA = gridApi;
                    this.apiCA.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiCA.selection.selectRow(newCol.row.entity);
                        });
                    });
                  
                },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.diUpdateCtrl.removeInlineCA()" ng-disabled="row.entity.Attribute.IsNotEditable" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 60,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton">
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.diUpdateCtrl.insertCA()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Attribute',
                        displayName: '* Attribute',
                        field: 'Attribute',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="row.entity.Attribute.IsNotEditable">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" ng-disabled="row.entity.Attribute.IsNotEditable" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.diUpdateCtrl.loadAttributeList()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 210
                    },
                    {
                        name: 'AttributeValue',
                        displayName: '* Attribute Value',
                        field: 'CustomAttributeAnswerOption',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeNumericValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeDateValue | momentDateFilter: 'YYYY/MM/DD HH:mm'}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-dropdown ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable" ng-change="grid.appScope.diUpdateCtrl.attributeValueChanged(model, row.entity)"  ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}-AttributeValueDD" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.diUpdateCtrl.loadAttributeAnswerOptionList(row.entity.Attribute.Id)"></gts-dropdown>                                                                                                                                               
                                        
                                        <p class="input-group-sm" ng-if="row.entity.Attribute.AttributeType == 58">
                                        <input type="text" ng-change="grid.appScope.diUpdateCtrl.attributeValueOtherChanged( row.entity)" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValueText" ng-model="row.entity.AttributeValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>

                                        <p class="input-group-sm"  ng-if="row.entity.Attribute.AttributeType == 556">
                                        <input type="number" ng-change="grid.appScope.diUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValue" ng-model="row.entity.AttributeNumericValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" min="0" />
                                        </p>

                                        <gts-date ng-change="grid.appScope.diUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable"  format="yyyy/MM/dd HH:mm"  show-time="true" ng-model="row.entity.AttributeDateValue" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                </form>
                               `,
                        enableSorting: false,
                        width: 210
                    }
                    , {
                        name: "IsActive",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive" ng-change="grid.appScope.diUpdateCtrl.checkBoxAttributeChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            };

            //Delivery Instruction Custom Attribute END

            SaveDI() {
                if (this.apiDISundry) {
                    this.deliveryInstruction.DeliveryInstructionSundryItems = _.map(this.apiDISundry.rowEdit.getDirtyRows(this.apiDISundry.grid), (o) => { return o.entity });
                } else {
                    this.deliveryInstruction.DeliveryInstructionSundryItems = [];
                }

                if (this.apiDIS) {
                    this.deliveryInstruction.DeliveryInstructionSKUAllocations = _.map(this.apiDIS.rowEdit.getDirtyRows(this.apiDIS.grid), (o) => { return o.entity });
                } else {
                    this.deliveryInstruction.DeliveryInstructionSKUAllocations = [];
                }

                if (this.apiPRList) {
                    this.deliveryInstruction.DeliveryInstructionPRList = _.map(this.apiPRList.rowEdit.getDirtyRows(this.apiPRList.grid), (o) => { return o.entity });
                } else {
                    this.deliveryInstruction.DeliveryInstructionPRList = [];
                }

                return this.deliveryInstructionService.save().save(this.deliveryInstruction, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);
                    if (!data.HasErrorMessage) {

                        if (this.apiDISundry) {
                            this.apiDISundry.rowEdit.setRowsClean(this.deliveryInstruction.DeliveryInstructionSundryItems);
                        }

                        if (this.apiDIS) {
                            this.apiDIS.rowEdit.setRowsClean(this.deliveryInstruction.DeliveryInstructionSKUAllocations);
                        }

                        if (this.apiPRList) {
                            this.apiPRList.rowEdit.setRowsClean(this.deliveryInstruction.DeliveryInstructionPRList);
                        }

                    }
                }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            };

            Save() {
                this.generalService.displayConfirmationBox("Confirm", "Save Delivery Instruction?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'di.update'
                        },
                            () => {

                                return this.SaveDI().then((msgHandler: interfaces.applicationcore.IMessageHandler) => {
                                    if (this.deliveryInstruction.Id)
                                        this.loadDI();
                                    else {
                                        this.$state.params["diId"] = Number(msgHandler.ID);
                                        this.$state.go('auth.PurchaseOrders.Update.Di', this.$state.params, { reload: 'auth.PurchaseOrders.Update.Di' });
                                    }
                                });
                            });
                    }
                });
            }

            SaveClose() {
                this.generalService.displayConfirmationBox("Confirm", "Save Delivery Instruction?").then((result) => {

                    if (result) {

                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'di.update'
                        },
                            () => {
                                return this.SaveDI().then((data) => {
                                    if (data) {
                                        this.$state.go("^");
                                        this.$anchorScroll("topAnchor");
                                    } else {
                                        this.$anchorScroll("topAnchor");
                                    }

                                });
                            });
                    }
                });
            }

            Close() {
                this.$state.go("^");
            }
        }

        angular.module("app").controller("deliveryInstructionUpdateCtrl", controllers.purchase.deliveryInstructionUpdateCtrl);
    }
}